<template>
  <span>
    {{ dots }}
  </span>
</template>

<script>
export default {
  data() {
    return {
      dotCount: 0,
      intervalId: null,
    };
  },
  computed: {
    dots() {
      return '.'.repeat(this.dotCount);
    },
  },
  created() {
    this.intervalId = setInterval(() => {
      this.dotCount = (this.dotCount % 3) + 1;
    }, 500);
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  },
};
</script>
