<template>
  <div class="uploaded-image">
    <div class="image-preview">
      <img
        :src="previewImage"
      >
    </div>
    <div class="upload-info">
      <div class="info-text-wrapper bold">
        <p><span class="image-name">&nbsp;{{ name }}</span></p>
      </div>
      <div v-if="isProcessed">
        <p
          v-if="noRegions"
          class="result-text bold red"
        >
          &nbsp; No regions were detected for this image.
        </p>
        <div v-else>
          <p class="result-text bold">
            &nbsp; Number of regions detected: {{ numberRegions }} <br>
            &nbsp; RoI Scores: {{ roundedScores }} <br>
            &nbsp; Scorad Predicion: {{ Severity_pred.scorad_tot['0'].toFixed(3) }} <br>
            <!-- &nbsp;Result: {{ result }} -->
          </p>
        </div>
      </div>
      <div v-else>
        Processing <LoadingDots />
      </div>
    </div>
    <div
      class="expand-info-icon"
      @click="expandInfo"
    >
      <img
        src="@/assets/expand-info-icon.svg"
        alt="Expand Info Icon"
      >
    </div>
  </div>
  <div
    v-if="showExpanded"
    class="expanded"
    @click.self="closeExpanded"
  >
    <div class="expanded-content">
      <span
        class="close"
        @click="closeExpanded"
      >&times;</span>
      <!-- Image and results here -->
      <img :src="previewImage">
      <p>{{ content.result }}</p>
    </div>
  </div>
</template>


<script>
import LoadingDots from '@/components/Misc/LoadingDots.vue';

import api from '@/services/api';

// Helper function to parse JSON, return empty string on error
function safeParseJSON(jsonString) {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return "";
  }
}

function parseResult(resultString) {
  // Note: this level of parsing is necessary because of the format results are returned
  // Parse result string into an object
  let resultObj = safeParseJSON(resultString);

  // Iterate over each property of resultObj and attempt to parse it
  for (let key in resultObj) {
    try {
      resultObj[key] = JSON.parse(resultObj[key]);
    } catch (e) {
      // console.error(`Failed to parse ${key}:`);
    }
  }

  return resultObj;
}

export default({
  name: 'ResultImage',
  components: {
    LoadingDots,
  },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    imageId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // Image data
      previewImage: "",
      content: {},
      name: "",
      isCloseup: false,
      status: "pending",
      result: {},
      RoI_locs: [],
      RoI_scores: [],
      Severity_pred: {},
      Severity_proba: {},      

      showExpanded: false,
      // for the "processing..." 3 dots effect
      dotCount: 0,
      dotInerval: null,
    };
  },
  computed: {
    noRegions() {
      return this.RoI_locs.length === 0;
    },
    numberRegions() {
      return this.RoI_locs.length;
    },
    roundedScores() {
      return this.RoI_scores.map(score => score.toFixed(3));
    },
    isProcessed() {
      return this.status === "complete";
    },
  },
  async created() {
    this.pollForResults();
    this.previewImage = await api.getImagePreview(this.uuid, this.imageId);
    this.loadContent();
  },
  methods: {
    expandInfo() {
      this.showExpanded = true;
    },
    closeExpanded() {
      this.showExpanded = false;
    },
    async loadContent() {
      this.content = await api.getImageData(this.uuid, this.imageId);

      this.name = this.content.name;
      this.isCloseup = this.content.is_closeup;
      this.status = this.content.status;

      this.result = parseResult(this.content.result);
      if (this.result) {
        this.RoI_locs = this.result.RoI_locs ?? [];
        this.RoI_scores = this.result.RoI_scores ?? "";
        this.Severity_pred = this.result.Severity_pred ?? "";
        this.Severity_proba = this.result.Severity_proba ?? "";
      }
    },
    async pollForResults() {
      // Poll for results every 5 seconds
      const status = await api.pollImageStatus(this.uuid, this.imageId)
      this.status = status;
      this.loadContent();
    },
  },
});


</script>


<style scoped>
.uploaded-image {
  position: relative;
  box-sizing: border-box;
  min-height: 150px;
  height: 100%;
  margin: 4px 0;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #E3E3E3;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  transition: border 0.2s ease;
}

.image-preview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview img {
  width: 100px;
  max-height: 80%;
  /* object-fit: contain; */
  margin-left: 15px;
}

.upload-info {
  box-sizing: border-box;
  padding: 0.5em;
  margin: 0 0 0 1em;
  font-size: 0.75em;
  font-weight: 400;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: start;
  align-items: start;
  text-align: start;
}

.info-text-wrapper {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  overflow: hidden;
  margin-right: 5px;
  font-size: 1.0em;
}
 
.image-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.expand-info-icon {
  /* background-color: #00AB11; */
  height: 100%;
  width: 28px;
  align-self: center;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease;
}

.expand-info-icon:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.bold {
  font-weight: 600;
}

.red {
  color: #9c0000;
}

/* For the pop-up with the expanded info */
.expanded {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.expanded-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 15px solid rgb(115, 175, 113);
  border-radius: 8px;
  width: 80%;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;
  word-wrap: break-word;
  white-space: normal;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

</style>