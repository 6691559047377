<template>
  <div class="form-container">
    <div class="header-row">
      <h1 class="title">
        Part 2: Image Upload
      </h1>
    </div>
    <hr class="separator">
    <template v-if="Object.keys(image_ids).length === 0">
      <p
        v-if="loadingImages"
        class="message-text"
      >
        Loading Images
      </p>
      <p
        v-else
        class="message-text"
      >
        No images were uploaded
      </p>
    </template>
    <template
      v-for="(ids, region) in image_ids"
      v-else
      :key="region"
    >
      <h2 class="region-text">
        Region: {{ region }}
      </h2>
      <ImageResults 
        :image-ids="ids"
        :uuid="uuid"
      />
    </template>
  </div>
</template>

<script>
import ImageResults from './ImageResults.vue';

import api from '@/services/api';

export default {
  name: 'ImageResultsPage',
  components: { ImageResults },
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingImages: true,
      image_ids: {},
    };
  },
  async created() {
    try {
      this.image_ids = await api.getImageIds(this.uuid);
    } catch (error) {
      console.log("unable to fetch ids", error);
    }
    this.loadingImages = false;
  },
}

</script>

<style scoped>

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}
.header-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0.5em;
}

.title {
  margin-right: 10px;
  margin-bottom: 0.25em;
  margin-left: 0.2em;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 2em;
}

.region-text {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 1.375em;
  margin: 0 0 0 3em;
}

.separator {
  width: calc(100% + 40px); /* adding twice the padding to the width */
  margin-left: -20px;
  margin-right: -20px;
  /* border-top: 1px solid #000; */
}

.message-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5;
  margin: 3em 0px 3em 3em;
  /* text-align: left; */
}



</style>