<template>
  <div id="app">
    <HeaderBanner />
    <router-view />
  </div>
</template>

<script>
import HeaderBanner from './components/Layout/HeaderBanner.vue';

export default {
  name: 'App',
  components: {
    HeaderBanner,
  },
  mounted() {
    if (!this.$store.getters.userData) {
      this.$store.dispatch('fetchUserData');
    }
  },
};
</script>


<style>

html, body {
  height: auto;
  margin: 0;
  padding: 0;
}

#app {
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
  text-align: center;
}

</style>