<template>
  <div class="submit-container">
    <p class="thanks">
      Thank you!
    </p>
    <p class="form-submitted">
      The form has been submitted.
    </p>
    <div class="form-submitted">
      <p>
        For testing purposes you can check the results by clicking the button below.
      </p>
      <button
        class="results-button"
        @click="goToResults"
      >
        Go to results page
      </button>
    </div>
    <p class="more-info">
      For any further queries contact ...
    </p>
    <img
      src="@/assets/done-icon.svg"
      class="done-icon"
    >
  </div>
</template>

<script>
export default {
  name: 'SubmittedPage',
  props: {
    uuid: {
      type: String,
      required: true,
    },
  },
  methods: {
    async goToResults() {
      this.$router.push('/results/' + this.uuid);
    },
  },
}

</script>

<style scoped>

.submit-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  align-self: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.thanks, .form-submitted {
  margin-bottom: 0em;
}

.thanks {
  font-size: 2em;
}

.form-submitted {
  font-size: 1.2em;
}

.more-info {
  font-size: 0.8em;
  margin-top: 0.5em;
}

.done-icon {
  /* width: 100%; */
  height: 150px;
  max-height: 300px;
  margin-top: 2em;
}

.results-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 1.2em;
  width: 60%;
  max-width: 400px;
  margin: 1em 5px;
  padding: 10px 20px;
  border-radius: 7px;
  border: none;
  background-color: #064896;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

</style>