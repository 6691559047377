<template>
  <div class="form-container">
    <div class="header-row">
      <h1 class="title">
        Part 2: Image Upload
      </h1>
      <div
        class="info-icon"
        @click="toggleHelpText"
      >
        <img
          src="@/assets/info_icon.svg"
          alt="Info"
        >
      </div>
    </div>
    <div
      v-if="showHelpText"
      class="help-text"
    >
      <p1>
        This form consists of two parts. 1. Questionnaires and 2. Image Upload. 
        <br> Instructions in more detail about image upload...
      </p1>
    </div>
    <hr class="separator">
    <template
      v-if="!regions.size"
    >
      <div class="instruction-text">
        <p class="bold-text">
          There are no images of infected regions to upload, as you did not identify any in the previous section.
          <span v-if="genitalsSelected">(Excluding the genital region)</span>
        </p>
        <p class="italic-text">
          If this was a mistake go back to the previous section (PO-SCORAD) and identify the infected regions.
        </p>
        <p class="italic-text">
          Otherwise press Submit to finish the questionnaire.
        </p>
      </div>
    </template>
    <template
      v-else
    >
      <div class="instruction-text">
        <p class="bold-text">
          Finally, please upload images of the infected regions that you identified in the previous section.
          <span v-if="genitalsSelected">(Excluding the genital region)</span>
        </p>
        <p class="italic-text">
          <!-- For each of the regions, upload an overview image, and between 1 to 4 close-up shots of the infected areas. -->
          For each of the regions, upload upload up to 5 shots of the infected areas.
        </p>
      </div>
  
      <template
        v-for="(region, index) in regions"
        :key="index"
      >
        <h2 class="region-text">
          {{ region }}
        </h2>
        <ImageUpload 
          :uuid="uuid"
          :all-images="answers[region]"
          :region="region"
          @update="updateAnswers(region, $event)"
          @all-images-uploaded="updateImagesUploaded(index, $event)"
        />
      </template>
    </template>
  </div>
</template>
  
<script>
import ImageUpload from './ImageUpload.vue';

export default {
  name: 'ImageUploadPage',
  components: { ImageUpload },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    answers: {
      type: Object,
      default: () => ({}),
    },
    regions: {
      type: Set,
      required: true,
      default: () => new Set(),
    },
    genitalsSelected: {
      type: Boolean,
      default: false,
    },
  },
emits: ['update-answers', 'all-images-uploaded'],
  data() {
    return {
      showHelpText: false,
      localAnswers: {},
      imagesUploaded: Array(this.regions.size).fill(false),
    };
  },
  computed: {
    allQuestionsAnswered() {
      return this.imagesUploaded.every(imageUploaded => imageUploaded);
    }
  },
  watch: {
    allQuestionsAnswered(newValue) {
      this.$emit('all-images-uploaded', newValue)
    }
  },
  created() {
    if (this.answers) {
      this.localAnswers = { ...this.answers };
    }
    if (this.regions.size === 0) {
      this.$emit('all-images-uploaded', true);
    }
  },
  methods: {
    toggleHelpText() {
      this.showHelpText = !this.showHelpText;
    },
    updateAnswers( region, images ) {
      this.localAnswers[region] = images;
      this.$emit('update-answers', {answers: this.localAnswers } );
    },
    updateImagesUploaded( index, allImagesUploaded ) {
      this.imagesUploaded[index] = allImagesUploaded;
    },
  },
};
</script>
  
<style scoped>
.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 0.5em;
}

.title {
  margin-right: 10px;
  margin-bottom: 0.25em;
  margin-left: 0.2em;
  font-family: 'DM Sans', sans-serif;
  font-weight: 600;
  font-size: 2em;
}

.region-text {
  font-family: 'Mulish', sans-serif;
  font-weight: 700;
  font-size: 1.375em;
  margin: 0 0 0 3em;
}

.info-icon {
  justify-content: center;
  align-items: center;
  /* translate both in x and y direction */
  margin-left: 1.2em;
  transform: translateY(35%);
  width: 1.5em;
  cursor: pointer;
}

.info-icon img {
  width: 100%;
}

.help-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 1em;
  color: #830505;
  margin-bottom: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  line-height: 1.5em;
  text-align: left;
}

.separator {
  width: calc(100% + 40px); /* adding twice the padding to the width */
  margin-left: -20px;
  margin-right: -20px;
  /* border-top: 1px solid #000; */
}

.instruction-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1;
  margin: 0px 0px 10px 3em;
  text-align: left;
}

.bold-text {
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}

</style>
