import { createStore } from 'vuex';
import api from './api';

export default createStore({
  state: {
    userData: null,
  },
  mutations: {
    SET_USER_DATA(state, payload) {
      state.userData = payload;
    },
  },
  actions: {
    setUserData({ commit }, payload) {
      commit('SET_USER_DATA', payload);
    },
    async fetchUserData({ commit }) {
      const userDataFromAPI = await api.getUserData();
      console.log('userDataFromAPI', userDataFromAPI);
      commit('SET_USER_DATA', userDataFromAPI);
    },
  },
  getters: {
    userData: (state) => state.userData,
  },
});
