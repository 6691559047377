<template>
  <div :class="{ 'uploaded-image': true, 'missing-image-error': true }">
    <p
      class="info-text"
      v-html="text"
    />
  </div>
</template>

<script>
export default({
  name: 'OverviewPlaceholder',
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
})
</script>


<style scoped>
.uploaded-image {
  position: relative;
  box-sizing: border-box;
  min-height: 50px;
  margin: 4px 0;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #E3E3E3;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  transition: border 0.2s ease;
}

.missing-image-error {
  border: 2px solid #E41D1D;
  color: #E41D1D;
}

.info-text {
  box-sizing: border-box;
  padding: 0.8em 1em;
  margin: 0 0 0 0em;
  font-size: 0.75em;
  font-weight: 700;
}

</style>