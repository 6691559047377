import { createRouter, createWebHashHistory } from 'vue-router'
import FormHandler from './components/FormHandler.vue'
import ResultsPage from './components/Results/ResultsPage.vue'
// import LandingPage from './components/LandingPage.vue'
import LoginPage from './components/LoginPage.vue'
import HomePage from './components/HomePage.vue'
import auth from './services/auth';

const routes = [
  {
    path: '/form',
    name: 'FillFormRoute',
    component: FormHandler,
  },
  {
    path: '/results/:uuid',
    name: 'ResultsRoute',
    component: ResultsPage,
    props: true,
  },
  // {
  //   path: '/',
  //   name: 'LandingRoute',
  //   component: LandingPage
  // },
  {
    path: '/login',
    name: 'LoginRoute',
    component: LoginPage
  },
  {
    path: '/',
    name: 'HomeRoute',
    component: HomePage
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // Forward to login page if not authenticated
  if (to.name !== 'LoginRoute' && !auth.isAuthenticated()) {
    next({ name: 'LoginRoute' });
  } else {
    next();
  }
});

export default router
