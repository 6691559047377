<template>
  <div class="image-upload-container">
    <div class="uploaded-images">
      <!-- display the images that the user has uploaded -->
      <ResultImage
        v-for="(id, index) in imageIds"
        :key="index"
        :image-id="id"
        :uuid="uuid"
      />
    </div>
  </div>
</template>

<script>
import ResultImage from './ResultImage.vue';

export default {
  name: 'ImageResults',
  components: { ResultImage },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    imageIds: {
      type: Object,
      required: true,
    },
  },
};

</script>

<style scoped>
.image-upload-container {
  box-sizing: border-box;
  width: 95%;
  align-self: center;
  background: rgb(115, 175, 113);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 1em 1em;
  /* margin: 15px; */
  font-family: 'Mulish', sans-serif;
  margin: 2em;
}

.uploaded-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  /* add space between elements */
  gap: 8px;
}

</style>