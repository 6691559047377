<template>
  <FormContainer :show-progress-bar="false">
    <div class="welcome-container">
      <p class="welcome">
        Welcome
      </p>
      <p class="landing-info">
        This is the EczemaNet landing page.
      </p>
      <form class="login-container">
        <p class="landing-info">
          Sign in
        </p>
        <div class="input-container">
          <input
            v-model="email"
            class="input-box"
            type="text"
            placeholder="Enter email"
          >
          <div>
            <input
              v-model="password"
              class="input-box"
              :type="showPasswordType"
              placeholder="Password"
            >
            <img
              src="@/assets/hide-pswd-icon.svg"
              class="hide-pswd-icon"
              @click="showPassword = !showPassword"
            >
          </div>
          <!-- <input
            id="togglePassword"
            type="checkbox"
          >👁 -->
        </div>
        <div class="forgot-password">
          <a
            href="#"
            class="forgot-password-link"
          >Forgot password?</a>
        </div>
        <div
          v-if="loginFailed"
          class="error-message"
        >
          Invalid credentials, please try again.
        </div>
        <button
          class="login-button"
          @click="handleLogin"
        >
          Login
        </button>
      </form>
      <p class="more-info">
        If you do not have an account please contact your clinician.
        <br>
        For any further queries contact ...
      </p>
    </div>
  </FormContainer>
</template>

<script>
import FormContainer from './Layout/FormContainer.vue'

// import api from '@/services/api';
import auth from '@/services/auth';

export default {
  name: 'LandingPage',
  components: {
    FormContainer,
  },
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
      loginFailed: false,
    }
  },
  computed: {
    showPasswordType() {
      return this.showPassword ? 'text' : 'password';
    },
},
  methods: {
    // TODO:
    async handleLogin() {
      try{
        await auth.login(this.email, this.password);
        this.$router.push('/');
      } catch (error) {
        console.error("There was an error logging in:", error);
        this.loginFailed = true;
      }
    },
  },
}

</script>

<style scoped>

.welcome-container {
  margin-top: 2.2em;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  align-self: center;
  height: 100%;
  align-items: center;
  min-height: 50vh;
  justify-content: flex-start;
}

.welcome, .landing-info {
  margin: 10px;
}

.welcome {
  font-size: 2.2em;
}

.landing-info {
  font-size: 1.2em;
  width: 80%;
}

.more-info {
  font-size: 0.8em;
  margin-top: 2em;
  /* text-align: center; */
  /* margin-bottom: 20px; */
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 20px;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    margin-top: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 0px;
}

.input-box {
  width: 200px;
  background-color: #e9f3ff;
  border: 0;
  border-radius: 4px;
  padding: 12px 15px;
  font-size: 0.8em;
  /* font color */
  color: #064896;
  font-family: 'DM Sans', sans-serif;
  /* add shadow */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 5px;
}

.input-box:focus {
  outline: 2px solid #064896;
}

.input-box::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #064896;
  opacity: 1; /* Firefox */
}

.hide-pswd-icon {
  position: relative;
  width: 15px;
  height: 15px;
  right: 30px;
  top: 6px;
  cursor: pointer;
}

/* #togglePassword {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
} */

.forgot-password {
    text-align: right;
    margin-top: -10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.forgot-password-link {
    font-size: 0.7em;
    color: #064896;
    text-decoration: none;
}


.login-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 1.2em;
  width: 200px;
  margin: 1em 5px;
  padding: 10px 20px;
  border-radius: 7px;
  border: none;
  background-color: #064896;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}


</style>