import api from './api';
import store from './store';

// token is stored in local storage

export default {
  getToken() {
    return localStorage.getItem('token');
  },

  saveToken(token) {
    localStorage.setItem('token', token);
  },

  removeToken() {
    localStorage.removeItem('token');
  },

  isAuthenticated() {
    const token = this.getToken();
    if (!token) return false;

    // check its not expired
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const currentTime = Math.floor(Date.now() / 1000); // Date.now() returns milliseconds, converting to seconds
      if (payload.exp < currentTime) {
        console.log("Token expired")
        this.removeToken();
        return false;
      }
      return true;
    } catch (error) {
      console.error("Invalid token", error);
      return false;
    }
  },

  // Authenticate using the API
  async login(username, password) {
    const responseData = await api.login(username, password);
    if (responseData && responseData.accessToken) {
      this.saveToken(responseData.accessToken);
    }
    store.dispatch('setUserData', responseData);
    return responseData;
  },

  logout() {
    this.removeToken();
    store.dispatch('setUserData', null);
  },

  // async register(userData) {},
}
