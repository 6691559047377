<template>
  <div
    class="progress-container"
    :class="{ fixed: fixed }"
  >
    <div
      class="progress-bar"
      :style="{ width: progress + '%' }"
    >
      <div class="progress-glow" />
    </div>
  </div>
</template>
  
  <script>
  export default {
    props: {
      start: {
        type: Number,
        default: 0,
      },
      end: {
        type: Number,
        default: 100,
      },
      value: {
        type: Number,
        default: undefined,
      },
      fixed: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        progress: this.value !== undefined ? this.value : this.start,
      };
    },
    watch: {
      value(newValue) {
        if (newValue !== undefined) {
          this.progress = newValue;
        }
      },
    },
    mounted() {
      if (this.fixed) {
        window.addEventListener('scroll', this.updateProgressScroll);
      }
    },
    beforeUnmount() {
      if (this.fixed) {
        window.removeEventListener('scroll', this.updateProgressScroll);
      }
    },
    methods: {
      updateProgressScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        this.progress = this.start + (scrollTop / scrollHeight) * (this.end - this.start);
      },
    },
  };
  </script>
  
  <style scoped>
  .progress-container {
    position: relative;
    height: 15px;
    background: #385682;
    border-radius: 10px;
  }
  
  .progress-container.fixed {
    position: fixed;
    width: 60%;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .progress-bar {
    position: relative;
    height: 100%;
    background: #5297FF;
    border-radius: 10px;
    /* box-shadow: 0px 0 10px #33aaff; */
  }

  /* .progress-glow {
    position: absolute;
    right: 0;
    width: 20px;
    height: 100%;
    box-shadow: 0px 0 10px #33aaff;
} */
  </style>
  