<template>  
  <div class="question-container">
    <p
      class="question-text"
      v-html="questionText"
    />
    <p
      v-if="!isResultsPage"
      class="no-regions-text"
    >
      If no regions are affected, please click this 
      <button 
        class="no-regions-button"
        :class="{ 'no-regions-active': noRegionsButton }" 
        @click="toggleNoRegions"
      >
        No Regions Affected
      </button>
    </p>
    <div 
      :class="{'diagram-container': true, 'column-container': expanded_view, 'disabled-diagram': noRegionsButton}"
    >
      <div
        v-if="noRegionsButton"
        class="overlay-text"
      >
        No Regions Affected (specified above)
      </div>
      <div :class="{ 'aspect-ratio-preserver': true, 'aspect-ratio-preserver-expanded': expanded_view}">
        <div class="svg-container">
          <AdultDiagramFront 
            class="human-diagram"
            :selected-regions="localSelectedRegions['front']"
            @update-regions="regions => selectRegion(regions, 'front')"
            @genitals-selected="genitalsSelected"
          />
        </div>
      </div>
      <div :class="{ 'aspect-ratio-preserver': true, 'aspect-ratio-preserver-expanded': expanded_view}">
        <div class="svg-container">
          <AdultDiagramBack 
            class="human-diagram"
            :selected-regions="localSelectedRegions['back']"
            @update-regions="regions => selectRegion(regions, 'back')"
          />
        </div>
      </div>
      <!-- Don't show expand button for results page -->
      <div 
        v-if="!expanded_view && !isResultsPage"
        class="expand_icon"
        @click="expanded_view = true"
      >
        <img 
          src="@/assets/expand_icon.svg"
          alt="Expand"
        >
      </div>
      <div 
        v-if="expanded_view"
        class="expand_icon"
        @click="expanded_view = false"
      >
        <img 
          src="@/assets/minimize_icon.svg"
          alt="Expand"
        >
      </div>
    </div>
  </div>
</template>

<script>
import AdultDiagramBack from './AdultDiagramBack.vue';
import AdultDiagramFront from './AdultDiagramFront.vue';

const NUM_FRONT_REGIONS = 19;
const NUM_BACK_REGIONS = 17;
const idToRegion = new Map([
  // these region names are linked to those accepted in the backend
  // must be changed with the backend accordingly
  // -- front diagram --
  [0, 'Head & Neck'],
  [1, 'Head & Neck'],
  [2, 'Upper Limbs'],
  [3, 'Upper Limbs'],
  [4, 'Upper Limbs'],
  [5, 'Upper Limbs'],
  [6, 'Upper Limbs'],
  [7, 'Upper Limbs'],
  [8, 'Upper Limbs'],
  [9, 'Upper Limbs'],
  [10, 'Trunk'],
  [11, 'Trunk'],
  [12, ''], // genitals
  [13, 'Lower Limbs'],
  [14, 'Lower Limbs'],
  [15, 'Lower Limbs'],
  [16, 'Lower Limbs'],
  [17, 'Lower Limbs'],
  [18, 'Lower Limbs'],
  // -- back diagram --
  [19, 'Head & Neck'],
  [20, 'Head & Neck'],
  [21, 'Upper Limbs'],
  [22, 'Upper Limbs'],
  [23, 'Upper Limbs'],
  [24, 'Upper Limbs'],
  [25, 'Upper Limbs'],
  [26, 'Upper Limbs'],
  [27, 'Upper Limbs'],
  [28, 'Upper Limbs'],
  [29, 'Back'],
  [30, 'Back'],
  [31, 'Lower Limbs'],
  [32, 'Lower Limbs'],
  [33, 'Lower Limbs'],
  [34, 'Lower Limbs'],
  [35, 'Lower Limbs'],
]);

export default{
  name: 'DiagramQuestion',
  components: { AdultDiagramFront, AdultDiagramBack },
  props: {
    questionId: {
      type: String,
      required: true
    },
    questionText: {
      type: String,
      required: true,
    },
    selectedRegionKeys: {
      type: Array,
      default: () => [],
    },
    isResultsPage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update', 'update-regions', 'genitals-selected'],
  data() {
    return {
      noRegionsButton: this.selectedRegionKeys.includes(-1),
      localSelectedRegions: this.keysToRegions(this.selectedRegionKeys),
      savedSelectedRegions: this.keysToRegions([]),
      expanded_view: false,
    };
  },
  computed: {
    regionKeys() {
      return this.noRegionsButton ? [-1] : this.regionsToKeys(this.localSelectedRegions);
    },
    regionNames() {
      return this.selectedRegionNames(this.regionKeys);
    },
  },
  methods: {
    emitUpdate() {
      this.$emit('update', {
        questionId: this.questionId,
        selectedOption: this.regionKeys,
      });

      this.$emit('update-regions', this.regionNames);
    },
    keysToRegions(keys) {
      const regions = { 
        front: new Array(NUM_FRONT_REGIONS).fill(false),
        back: new Array(NUM_BACK_REGIONS).fill(false) 
      };

      if (keys.includes(-1)) {
        return regions;
      }
      keys.forEach((key) => {
        if (key < NUM_FRONT_REGIONS) {
          regions.front[key] = true;
        } else {
          regions.back[key - NUM_FRONT_REGIONS] = true;
        }
      });
      return regions;
    },
    regionsToKeys(regions) {
      const keys = [];
      regions.front.forEach((region, index) => {
        if (region) keys.push(index);
      });
      regions.back.forEach((region, index) => {
        if (region) keys.push(index + NUM_FRONT_REGIONS);
      });
      return keys;
    },
    toggleNoRegions() {
      // if user is clicking "No Regions Affected"
      if (!this.noRegionsButton) {
        this.noRegionsButton = true
        this.savedSelectedRegions = this.localSelectedRegions;
        this.localSelectedRegions = {}
      } else {
        this.noRegionsButton = false
        this.localSelectedRegions = this.savedSelectedRegions
      }
      this.emitUpdate();
    },
    selectRegion(regions, side) {
      this.localSelectedRegions[side] = regions;
      this.emitUpdate();
    },
    selectedRegionNames(regionKeys) {
      if (regionKeys.includes(-1)) {
        return new Set();
      }
      else {
        let selectedRegionNames = regionKeys.map(id => idToRegion.get(id));
        // remove empty strings (genitals) (or if no regions button selected: -1)
        // (coded this way to avoid accidentally asking for genitals in the image upload section)
        selectedRegionNames = selectedRegionNames.filter(name => name);
        return new Set(selectedRegionNames);
      }
    },
    genitalsSelected(value) {
      this.$emit('genitals-selected', value);
    },
  },

}

</script>

<style>

.question-container {
  width: 90%;
  padding-bottom: 3em;
  margin-left: 50px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
}

.diagram-container {
  position: relative;
  box-sizing: border-box;
  padding: 1em;
  border: 15px solid #e0e0e0;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 auto;  Center the container */
  margin-left: 1.5em;
}

.column-container {
  flex-direction: column;
}

.disabled-diagram {
  pointer-events: none; /* Make it non-interactable */
  opacity: 0.3; /* Make it appear "greyed out" */
}

.no-regions-text {
  /* font-size: 1.2em; */
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  font-family: 'Roboto', sans-serif;
}

.no-regions-button {
  border: 1px solid #686868;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  background: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 4px; 
  border-radius: 8px;
  width: 200px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.no-regions-button:hover {
  background-color: var(--infected-region-hover-color);
}

.no-regions-button:active {
  background-color: var(--infected-region-color);
  color: white;
}

.no-regions-active {
  background-color: var(--infected-region-color);
  color: white;
}

.no-regions-active:hover {
  background-color: var(--infected-region-color);
  color: white;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  font-size: 2em;
  color: #000;
  text-align: center;
  background-color: white;
}

.expand_icon {
  position: absolute;
  cursor: pointer;
  top: 2em;
  right: 2em;
  width: 2em;
  /* margin: 10px; */
}

.expand_icon img {
  width: 100%;
}

.expand_icon:hover {
  opacity: 0.7;
}

.aspect-ratio-preserver {
  position: relative;
  width: 40%;
  padding-top: 90%; /* Adjust the padding value for different aspect ratios */
}

.aspect-ratio-preserver-expanded {
  width: 100%;
  padding-top: 200%;
}

.svg-container {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  /* Now the SVG will resize to fit the container, preserving aspect ratio */
}

.human-diagram {
  width: 100%;
  height: 100%;
}


.question-text {
  margin-bottom: 10px;
  font-size: 1em;
}

@media (max-width: 600px) {
  .question-container {
    margin-left: 0;
    width: 100%;
  }

  .diagram-container {
    margin-left: 0;
  }

  .no-regions-button {
    margin-top: 10px;
  }
}

/* styles for the children components DiagramFront and DiagramBack */

.infected-region {
  fill: var(--infected-region-color);
  stroke: var(--infected-region-stroke);
  stroke-width: 1px;
  cursor: pointer;
}

.infected-region:hover {
  fill: var(--infected-region-hover-color);
}

.non-infected-region {
  fill: var(--non-infected-region-color);
  cursor: pointer;
}

.non-infected-region:hover {
  fill: var(--infected-region-hover-color);
}

/* This will only apply when the device does not support hovering (like most touchscreens) */
@media (hover: none) {
  .infected-region:hover {
    fill: var(--infected-region-color);
  }
  .non-infected-region:hover {
    fill: var(--non-infected-region-color);
  }
}

</style>