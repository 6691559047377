<template>
  <div class="image-upload-container">
    <div
      class="upload-area"
      @dragover.prevent
      @drop="onDrop"
    >
      <!-- drag-and-drop area for uploading images -->
      <div
        class="upload-icon"
      >
        <img
          src="@/assets/upload-icon.svg"
          alt="Upload Icon"
        >
      </div>
      <p class="upload-instructions">
        Drag & drop files or 
        <button
          class="browse-button"
          @click="browseFiles"
        >
          Browse
        </button>
      </p>
      <input
        ref="fileInput"
        type="file"
        style="display: none"
        accept="image/png, image/jpeg"
        multiple
        @change="event => onImageUpload(event.target.files)"
      >
      <p class="upload-support">
        Supported formates: JPEG, PNG
      </p>
    </div>
    <div
      v-if="local_images.length !== 0 || local_overviewImage"
      class="uploaded-images"
    >
      <p :class="{ 'upload-text': true, 'image-missing': imagesMissing }">
        <!-- Close-up Images -->
        Uploaded Images
      </p>
      <!-- display the images that the user has uploaded -->
      <image-placeholder
        v-if="local_images.length === 0" 
        text="missing image"
      />
      <uploaded-image
        v-for="image in local_images"
        :key="`${image.name}_${image.size}_${image.lastModified}`"
        :image="image"
        :show-confirm-message="confirmOverviewImage === image"
        class="uploaded-image-bar"
        @cancel-upload="cancelUpload"
        @set-overview="setOverviewImage"
        @unset-confirm-overview="unsetConfirmOverview"
        @click="setConfirmOverview(image)"
      />
      <!-- <p :class="{ 'upload-text': true, 'image-missing': overviewMissig }">
        Overview Image
      </p> -->
      <!-- <image-placeholder
        v-if="!local_overviewImage" 
        text="Please press on the image above that is the overview image of the region.<br>
          (This should be the only image that is not a close-up)"
      /> -->
      <!-- <uploaded-image
        v-else
        :key="`${local_overviewImage.name}_${local_overviewImage.size}_${local_overviewImage.lastModified}`"
        :image="local_overviewImage"
        class="uploaded-image-bar"
        @cancel-upload="cancelOverviewUpload"
        @click="unsetOverviewImage(local_overviewImage)"
      /> -->
    </div>
  </div>
</template>

<script>
import UploadedImage from './UploadedImage.vue';
import ImagePlaceholder from './ImagePlaceholder.vue'
import api from '@/services/api';

let imageIdCounter = 0;

export default {
  name: 'ImageUpload',
  components: { UploadedImage, ImagePlaceholder },
  props: {
    uuid: {
      type: String,
      required: true,
    },
    allImages: {
      type: Object,
      required: false,
      default: () => ({images: [], overviewImage: null}),
    },
    region: {
      type: String,
      required: true,
    },
  },
  emits: ['update', 'all-images-uploaded'],
  data() {
    return {
      local_images: this.allImages.images,
      local_overviewImage: this.allImages.overviewImage,
      confirmOverviewImage: null,
    };
  },
  computed: {
    imagesMissing() {
      return this.local_images.length === 0;
    },
    overviewMissig() {
      return this.local_overviewImage === null;
    },
    // TODO: fix bug, when leaving and coming back to page, evaluates to false at first
    allImagesUploaded() {
      const anyMissing = this.imagesMissing // || this.overviewMissig;
      return !anyMissing 
        && this.local_images.every(image => image.status === "complete" ) 
        // && this.local_overviewImage.status === "complete";
    },
    local_allImages() {
      return {images: this.local_images, overviewImage: this.local_overviewImage};
    }
  },
  watch: {
    allImagesUploaded() {
      // TODO: also check status of images (need to be complete)
      this.$emit('all-images-uploaded', this.allImagesUploaded);
    },
    local_allImages() {
      this.$emit('update', this.local_allImages);
    },
  },
  methods: {
    onImageUpload(files) {
      if (this.local_images.length + (this.local_overviewImage ? 1 : 0) + files.length > 5) {
        alert('You can only upload up to 5 images at a time. (One overview image and four close-up images)');
        return;
      }
      for (let i = 0; i < files.length; i++) {
        // add each file to images array with initial progress of 0
        if (files[i].type !== 'image/png' && files[i].type !== 'image/jpeg') {
          alert('Invalid file type. Only PNG and JPEG files are allowed.');
          continue;
        }

        const matchesFile = (img, file) =>
          img.name === file.name &&
          img.size === file.size &&
          img.lastModified === file.lastModified;

        const existingImage = this.local_images.find((img) => matchesFile(img, files[i])) ||
          (this.local_overviewImage && matchesFile(this.local_overviewImage, files[i]));

        if (existingImage) {
          // if the file already exists, skip it
          continue;
        }
        const objectUrl = URL.createObjectURL(files[i]);

        const image = {
          name: files[i].name,
          id: imageIdCounter++,
          progress: 0,
          status: 'uploading',
          preview: objectUrl,
          size: files[i].size, // to check for duplicates
          lastModified: files[i].lastModified,  // to check for duplicates
        };
        this.uploadImage(files[i], image);
        this.local_images.push(image);
      }

      // reset the input value so the change event will fire if the user selects the same file again
      event.target.value = null;
    },
    onDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.onImageUpload(files);
    },
    async uploadImage(file, image) {
      api.uploadImage(this.uuid, file, this.region, image.id, progress => {
        // note: direct assignment to image.progress does not trigger a re-render
        // so we need to create a new object and replace the old one
        // images could be replaced with a map for more efficiency
        const updatedImage = { ...image, progress };

        this.updateImage(updatedImage);
      })
      // TODO: remove this logging
      .then(response => {
        const updatedImage = { ...image, progress: 100, status: 'complete' };
        this.updateImage(updatedImage);
        console.log("Upload image response", response);
      })
      .catch(() => {
        const updatedImage = { ...image, progress: 0, status: 'error' };
        this.updateImage(updatedImage);
      });
    },
    findImageIndex(image) {
      return this.local_images.findIndex(img => img.name === image.name && img.size === image.size && img.lastModified === image.lastModified);
    },
    updateImage(image) {
      // find the image with the given name and replace it with the new one
      const index = this.findImageIndex(image);
      this.local_images.splice(index, 1, image);
    },
    cancelUpload(image) {
      // find the image with the given name and get its index
      const imageIndex = this.findImageIndex(image);

      if (imageIndex > -1) {
        const imageId = this.local_images[imageIndex].id;
        api.cancelUpload(this.uuid, this.region, imageId);

        // remove the image from the array using splice
        this.local_images.splice(imageIndex, 1);
      }
    },
    cancelOverviewUpload() {
      api.cancelUpload(this.uuid, this.region, this.local_overviewImage.id);
      this.local_overviewImage = null;
    },
    browseFiles() {
      this.$refs.fileInput.click();
    },
    setConfirmOverview(image) {
      this.confirmOverviewImage = image;
    },
    unsetConfirmOverview() {
      this.confirmOverviewImage = null;
    },
    setOverviewImage(image) {
      if (this.local_overviewImage !== null) {
        this.local_images.push(this.local_overviewImage);
      }
      this.confirmOverviewImage = null;
      this.local_overviewImage = image;
      // remove image from local_images
      const imageIndex = this.findImageIndex(image);
      this.local_images.splice(imageIndex, 1);
    },
    unsetOverviewImage(image) {
      this.local_images.push(image);
      this.local_overviewImage = null;
    }
  },
};
</script>

<style scoped>
.image-upload-container {
  box-sizing: border-box;
  width: 95%;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background: rgba(6, 72, 150, 0.28);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 2em 1em;
  /* margin: 15px; */
  font-family: 'Mulish', sans-serif;
  margin: 2em;
  min-height: 400px;
}

.upload-area {
  min-height: 200px;
  max-height: 350px;
  flex: 0.4;
  background-color: #F8F8FF; /* or whatever color you prefer */
  border: 1px dashed rgba(56, 78, 183, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-instructions {
  font-size: 1;
  font-weight: 700;
  margin-bottom: 0.5em;
  color: #0F0F0F;
  text-align: center;
}

.browse-button {
  background: none;
  color: #483EA8;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  text-decoration: underline;
}

.upload-support {
  font-size: 0.75em;
  font-weight: 400;
  margin-bottom: 0.5em;
  /* line-height: 18px; */
  color: #676767;
  text-align: center;
}

.uploaded-images {
  flex: 0.6;
  margin-left: 1em;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  max-width: 60%;
  width: 60%;
}

.uploaded-image-bar{
  position: relative;
  width: 100%;
  cursor: pointer;
}

.uploaded-image-bar:hover {
  border: 2px solid #6d5dfa;
}

.confirm-overview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8); /* semi-transparent white overlay */
  /* other styles... */
}

.upload-text {
  font-size: 0.875em;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #676767;
}

.image-missing {
  color: #E41D1D;
}

@media (max-width: 600px) {
  .image-upload-container {
    flex-direction: column;
    min-height: 400px;
  }


  .upload-area {
    max-height: 200px;
    flex: 1;
  }

  .uploaded-images {
    box-sizing: border-box;
    flex: 1;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }


}


</style>
