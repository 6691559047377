import axios from 'axios';
import store from './store';

const baseUrl = process.env.VUE_APP_API_URL;

const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const fileUploadClient = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
});

const addTokenInterceptor = (client) => {
  client.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['X-Access-Token'] = token;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });
};

addTokenInterceptor(apiClient);
addTokenInterceptor(fileUploadClient);

export default {
  async login(username, password) {
    const response = await apiClient.post('/login', {
      username: username,
      password: password,
    });
    return response.data;
  },

  async getUserData() {
    const response = await apiClient.get('/get_user_data');
    return response.data;
  },

  async submitForm(data, uuid, poem_id, scorad_id) {
    const url = `/post_form/${uuid}?poem_id=${poem_id}&scorad_id=${scorad_id}`;
    const response = await apiClient.post(url, data);
    return response.data;
  },
  async getEntityIdStatus(uuid) {
    try {
      const response = await apiClient.get(`/get_status/${uuid}`);
      return response.data.status;
    } catch (error) {
      return 'error';
    }
  },

  async getImageStatus(uuid, image_id) {
    try {
      const response = await apiClient.get(`/get_image_status/${uuid}/${image_id}`);
      return response.data.status;
    } catch (error) {
      return 'error';
    }
  },

  async pollImageStatus(uuid, image_id) {
    let status = await this.getImageStatus(uuid, image_id);
    while (status === "pending" || status === 'processing') {
      await new Promise(r => setTimeout(r, 5000));
      // status = await this.getEntityIdStatus(uuid);
      status = await this.getImageStatus(uuid, image_id);
    }
    return status;
  },

  async uploadImage(uuid, imageFile, region, image_id, onProgress) {
    const formData = new FormData();
    formData.append('image', imageFile);

    const partParam = encodeURIComponent(region);
    const idParam = encodeURIComponent(image_id);
    const url = `/post_image/${uuid}?part=${partParam}&id=${idParam}`;

    return fileUploadClient.post(url, formData, {
      onUploadProgress: (progressEvent) => {
        let progress;
        progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(progress)
      },
    })
  },

  async cancelUpload(uuid, region, image_id) {
    const partParam = encodeURIComponent(region);
    const idParam = encodeURIComponent(image_id);
    const url = `/cancel_upload/${uuid}?part=${partParam}&id=${idParam}`;
    return apiClient.delete(url);
  },

  async setOverview(uuid, region, image_id) {
    const partParam = encodeURIComponent(region);
    const idParam = encodeURIComponent(image_id);
    const url = `/set_overview_image/${uuid}?part=${partParam}&id=${idParam}`;
    return apiClient.put(url);
  },

  async getResults() {
    const response = await apiClient.get('/get_forms'); // not implemented
    return response.data.forms;
  },

  async getResult(uuid) {
    const response = await apiClient.get(`/get_form/${uuid}`);
    return response.data;
  },

  // returns a dictionary of lists of image ids for each region
  async getImageIds(uuid) {
    const response = await apiClient.get(`/get_image_ids/${uuid}`);
    return response.data;
  },

  async getImagePreview(uuid, image_id) {
    const response = await apiClient.get(`/get_image_preview/${uuid}/${image_id}`);
    const base64String = response.data;
    const src = 'data:image/png;base64,' + base64String;
    return src;
  },

  async getImageData(uuid, image_id) {
    const response = await apiClient.get(`/get_image_data/${uuid}/${image_id}`);
    return response.data;
  },

  // TODO: check this later
  async getImage(uuid, name) {
    const response = await apiClient.get(`/get_image/${uuid}/${name}`);
    return 'data:image/png;base64,' + response.data;
  },

  async getNewFormId() {
    // TODO: obviously a better way to get the groupID rather than just take the first one 
    // const userId = store.getters.userData;
    const groupId = store.getters.userData.groups[0].id;
    const response = await apiClient.get(`/new_form/${groupId}`);
    const entityId = response.data.data.newEntityGroup.id;
    const poemId = response.data.data.questionnaireIds.POEM;
    const poscoradId = response.data.data.questionnaireIds.POSCORAD;
    return { entityId: entityId, poemId: poemId, poscoradId: poscoradId };
  }

};
