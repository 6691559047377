<template>
  <div :class="{ 'uploaded-image': true, 'uploaded-image-success': uploadSuccessful, 'uploaded-image-error': uploadError }">
    <div class="image-preview">
      <img
        :src="image.preview"
      >
    </div>
    <template v-if="showConfirmMessage">
      <div
        v-click-outside="() => $emit('unset-confirm-overview', image)"
        class="confirm-message"
      >
        <p>Set as overview image?</p>
        <div class="confirm-buttons">
          <button
            class="confirm-button yes-button"
            @click.stop="$emit('set-overview', image)"
          >
            Yes
          </button>
          <button
            class="confirm-button no-button"
            @click.stop="$emit('unset-confirm-overview', image)"
          >
            No
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="upload-info">
        <div class="upload-info-text">
          <div class="info-text-wrapper">
            <span class="image-name">{{ image.name }}</span>
            <span
              class="progress"
              style="color: #B4B4B4;"
            >  
              &nbsp;({{ image.progress }}%)
            </span>
          </div>
          <div
            v-if="image.status != 'uploading'"
            :class="{ 'upload-status': true, 'upload-status-success': uploadSuccessful, 'upload-status-error': uploadError }"
          >
            {{ image.status }}
          </div>
        </div>
        <progress-bar
          class="upload-progress-bar"
          :value="image.progress"
          :fixed="false"
        />
      </div>
      <div
        class="cancel-icon"
        @click.stop="cancelUpload(image)"
      >
        <img
          src="@/assets/cross_icon.svg"
          alt="Cancel Icon"
        >
      </div>
    </template>
  </div>
</template>


<script>
import ProgressBar from '@/components/Layout/ProgressBar.vue';

export default({
  name: 'UploadedImage',
  components: { ProgressBar },
  props: {
    image: {
      type: Object,
      required: true,
    },
    // showConfirmMessage: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  emits: ['cancel-upload', 'set-overview', 'unset-confirm-overview'],
  computed: {
    uploadSuccessful() {
      return this.image.status === 'complete';
    },
    uploadError() {
      return this.image.status === 'error';
    },
  },
  methods: {
    cancelUpload(image) {
      this.$emit('cancel-upload', image);
    },
  },
});


</script>


<style scoped>
.uploaded-image {
  position: relative;
  box-sizing: border-box;
  min-height: 50px;
  height: 20%;
  margin: 4px 0;
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid #E3E3E3;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  transition: border 0.2s ease;
}

.confirm-message {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 700;
}

/* .confirm-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
} */

.confirm-button {
  padding: 10px 20px;
  margin: 0 2px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.yes-button {
  background-color: #28a745;
}

.yes-button:hover {
  background-color: #218838;
}

.no-button {
  background-color: #dc3545;
}

.no-button:hover {
  background-color: #c82333;
}

.uploaded-image-success {
  border: 2px solid #00AB11;
}

.uploaded-image-error {
  border: 2px solid #E41D1D;
}

.upload-status {
  transition: color 0.2s ease;
}

.upload-status-success {
  color: #00AB11;
}

.upload-status-error {
  color: #E41D1D;
}

.image-preview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-preview img {
  width: 50px;
  max-height: 80%;
  /* object-fit: contain; */
  margin-left: 15px;
}

.upload-info {
  box-sizing: border-box;
  padding: 0.5em;
  margin: 0 0 0 1em;
  font-size: 0.75em;
  font-weight: 400;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: start;
}
.upload-info-text {
  width: 100%;
  height: 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.info-text-wrapper {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  overflow: hidden;
  margin-right: 5px;
}

.image-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.upload-progress-bar {
  /* margin-top: -1.7em; */
  width: 100%;
  height: 3px;
  border-radius: 4px;
}

/* .cancel-icon svg {
  left: 0;
  object-fit: fill;
  width: 100%;
  height: 100%;
} */

.cancel-icon {
  /* background-color: #00AB11; */
  height: 100%;
  width: 28px;
  align-self: center;
  transform: translateY(13%);
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease;
}

.cancel-icon img:hover {
  cursor: pointer;
  filter: brightness(70%);
}

</style>