<template>
  <FormContainer
    v-bind="currentForm.containerProps"
    :show-progress-bar="showProgressBar"
    v-on="currentForm.containerOn"
  >
    <template v-if="entityIdStatus"> 
      <SubmittedPage v-if="entityIdStatus === 'complete' || entityIdStatus === 'processing'" />
      <ErrorPage v-else-if="entityIdStatus === 'error'" />
      <component
        :is="currentForm.is"
        v-else-if="entityIdStatus === 'pending'"
        :uuid="entityId"
        :answers="currentForm.answers"
        v-bind="currentForm.props"
        v-on="currentForm.on"
      />
    </template>
  </FormContainer>
</template>

<script>
import FormContainer from './Layout/FormContainer.vue'
import PoemForm from './Questions/PoemForm.vue'
import ScoradForm from './Questions/ScoradForm.vue'
import ImageUploadPage from './Upload/ImageUploadPage.vue'
import SubmittedPage from './SubmittedPage.vue'
import ErrorPage from './ErrorPage.vue'
import SubmissionErrorPage from './SubmissionErrorPage.vue'

import api from '@/services/api';

export default {
  name: 'FormHandler',
  components: {
    FormContainer,
    PoemForm,
    ScoradForm,
    ImageUploadPage,
    SubmittedPage,
    ErrorPage,
    SubmissionErrorPage,
  },
  // props: {
  //   uuid: {
  //     type: String,
  //     required: true,
  //     // default: null,
  //   },
  // },
  data() {
    return {
      entityId: null,
      poemId: null,
      poscoradId: null,

      entityIdStatus: null,
      currentFormIndex: 5,
      forms: [
        {
          is: PoemForm,
          on: { 'update-answers': this.updateAnswers, 'all-questions-answered': this.updateAllQuestionsAnswered },
          props: { questionnaireName: "PoemForm" },
          // Container logic handles navigation
          containerOn: { next: this.goToNextPage },
          containerProps: { showNext: true, allQuestionsAnswered: false, scrollStart: 0, scrollEnd: 50 },
          answers: {},
        },
        {
          is: ScoradForm,
          on: { 'update-answers': this.updateAnswers,
            'update-regions': this.updateRegions,
            'all-questions-answered': this.updateAllQuestionsAnswered,
            'genitals-selected': this.updateGenitalsSelected,
          },
          props: { questionnaireName: "ScoradForm" },
          containerOn: { next: this.goToNextPage, previous: this.goToPreviousPage },
          containerProps: { showPrevious: true, showNext: true, allQuestionsAnswered: false, scrollStart: 50, scrollEnd: 100 },
          answers: {},
        },
        {
          is: ImageUploadPage,
          // TODO: check if its all-questions-answered here or something else (make it emit from image upload page)
          on: { 'update-answers': this.updateAnswers, 'all-images-uploaded': this.updateAllQuestionsAnswered },
          props: { questionnaireName: "ImageUploadPage", regions: new Set(), genitalsSelected: false },
          containerOn: { next: this.submitForm, previous: this.goToPreviousPage },
          containerProps: { showPrevious: true, showNext: true, nextText: "Submit" },
          answers: {},
        },
        {
          is: SubmittedPage,
          containerProps: { showPrevious: false, showNext: false },
        },
        {
          is: SubmissionErrorPage,
        },
        {
          is: ErrorPage,
        },
      ],
    };
  },
  computed: {
    currentForm() {
      return this.forms[this.currentFormIndex];
    },
    showProgressBar() {
      return this.currentFormIndex < this.forms.length - 3;
    },
  },
  async created() {
    this.entityId = this.$route.params.entityId;
    this.poemId = this.$route.params.poemId;
    this.poscoradId = this.$route.params.poscoradId;
    // TODO
    this.entityIdStatus = await api.getEntityIdStatus(this.entityId)
    if (this.entityIdStatus === 'complete' || this.entityIdStatus === 'processing') {
      this.currentFormIndex = 3;
    } else if (this.entityIdStatus === 'error') {
      this.currentFormIndex = 5;
    }
    else {
      this.currentFormIndex = 0;
    }
  },
  methods: {
    async submitForm() {
      try {
        const poemForm = this.forms[0].answers;
        const scoradForm = this.forms[1].answers;
        const images = this.forms[2].answers;

        // if scorad form has -1 in areas set to emptyt list
        if (scoradForm.areas.includes(-1)) {
          scoradForm.areas = [];
        }

        // set overview images in backend here
        // TODO: check if this is the right/ efficient way to do this
        Object.keys(images).forEach(async key => {
          const overviewImage = images[key].overviewImage;
          if (overviewImage) {
            const response = await api.setOverview(this.entityId, key, overviewImage.id);
            console.log(response)
          }
          else {
            console.log("no overview image")
          }
        })


        const response = await api.submitForm(
          { 
            poem_form: poemForm,
            scorad_form: scoradForm,
          },
          this.entityId,
          this.poemId,
          this.poscoradId,
        );
        console.log(response)
        this.goToNextPage();
      } catch (error) {
        console.error(error);
        console.log("poem_form", this.forms[0].answers)
        console.log("scorad_form", this.forms[1].answers)
        this.goToSubmissionErrorPage();
      }

    },
    goToNextPage() {
      if (this.currentFormIndex < this.forms.length - 1) {
        this.currentFormIndex++;
        window.scroll(0, 0);
      }
    },
    goToPreviousPage() {
      if (this.currentFormIndex > 0) {
        this.currentFormIndex--;
      }
    },
    goToSubmissionErrorPage() {
      this.currentFormIndex = this.forms.length - 2;
      window.scroll(0, 0);
    },
    updateAnswers({ answers }) {
      this.currentForm.answers = answers;
    },
    updateRegions(regions) {
      let imageUploadForm = this.forms.find(form => form.props.questionnaireName === "ImageUploadPage");
      if (imageUploadForm) {
        imageUploadForm.props.regions = regions;
      }
    },
    updateAllQuestionsAnswered(value) {
      this.currentForm.containerProps.allQuestionsAnswered = value;
    },
    updateGenitalsSelected(value) {
      let imageUploadForm = this.forms.find(form => form.props.questionnaireName === "ImageUploadPage");
      if (imageUploadForm) {
        imageUploadForm.props.genitalsSelected = value;
      }
    },
  },
}
</script>

