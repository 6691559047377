<template>
  <div class="question-container">
    <p
      class="question-text"
      v-html="questionText"
    />
    <div class="options-container">
      <!-- Render the options here -->
      <div
        v-for="(option, index) in options"
        :key="index"
        class="option-box"
        :class="{ selected: localSelectedOption === index }"
        tabindex="0" 
        @click="selectOption(index)"
        @keydown.enter="selectOption(index)"
      >
        <!-- tabindex to allow buttons to be navigable with keyboard -->
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultipleQuestion',
  props: {
    questionId: {
      type: String,
      required: true
    },
    questionText: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    selectedOption: {
      type: String,
      default: null,
    },
  },
emits: ['update'],
  data() {
    return {
      localSelectedOption: this.selectedOption,
    };
  },
  computed: {
    formattedQuestionText() {
      // Replace custom tags with appropriate HTML tags and styling
      let formattedText = this.questionText
        .replace(/<bold>(.*?)<\/bold>/g, '<span class="bold">$1</span>')
        .replace(/<grey>(.*?)<\/grey>/g, '<span class="grey">$1</span>')
        .replace(/<italic>(.*?)<\/italic>/g, '<span class="italic">$1</span>');

      return formattedText;
    }
  },
  watch: {
    selectedOption(newValue) {
      this.localSelectedOption = newValue;
    },
  },
  methods: {
    selectOption(index) {
      this.localSelectedOption = index;
      this.$emit('update', {
        questionId: this.questionId,
        selectedOption: this.localSelectedOption
      });
    }
  }
};
</script>

<style scoped>

.question-container {
  width: 90%;
  padding-bottom: 3em;
  margin-left: 50px;
  font-family: 'Roboto', sans-serif;
  text-align: left;
}

.question-text {
  margin-bottom: 10px;
  font-size: 1em;
}

.options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 8px;
  margin: 0 20px;
  font-weight: bold;
  width: 80%;
  min-width: 300px;
  max-width: 800px;
}

.option-box {
  border: 1px solid #686868;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  flex: 1;
  min-width: 0; /* This line and the following two lines handle long text gracefully */
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 4px; 
  border-radius: 8px;
  width: 60%;
}

.option-box:hover {
  background-color: var(--hover-color);
}

.option-box.selected {
  background-color: var(--selected-color);
}

/* Styling for screens less wide than 600px (essentially phones) */
@media (max-width: 600px) {
  .options-container {
    margin: 0;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .question-container {
    margin-left: 0;
    width: 100%;
    /* background-color: red; */
  }
}

</style>
