<template>
  <FormContainer :show-progress-bar="false">
    <div class="welcome-container">
      <!-- <p class="welcome">
        Welcome back
      </p> -->
      <div class="landing-info">
        <p>
          This is the EczemaNet home page. 
          <br>
          From here you can track your eczema symptoms.
        </p>
      </div>
      <!-- To log today's symptoms, click the button below. -->
      <button
        class="new-form-button"
        @click="goToNewForm"
      >
        Fill in New Form
      </button>
      <p class="more-info">
        <br>
        For any further queries contact ...
      </p>
      
      <div class="table-container">
        <div
          v-if="Object.keys(filteredQuestionnaires).length"
          class="table-responsive"
        >
          <table class="table table-bordered table-hover">
            <thead class="thead-dark">
              <tr>
                <th>Date Submitted</th>
                <th>POEM Score</th>
                <th>SCORAD Score</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(form, uuid) in filteredQuestionnaires"
                :key="uuid"
                @click="goToResults(uuid)"
              >
                <td>{{ formatDate(form.submitted_time) }}</td>
                <td>{{ form.poem_score }}</td>
                <td>{{ form.scorad_score }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>No historical data available.</p>
        </div>
      </div>
    </div>
  </FormContainer>
</template>

<script>
import FormContainer from './Layout/FormContainer.vue'

import api from '@/services/api';

export default {
  name: 'LandingPage',
  components: {
    FormContainer,
  },
  data() {
    return {
      questionnaires: [],
    };
  },
  computed: {
  filteredQuestionnaires() {
    return Object.keys(this.questionnaires)
      .filter(uuid => this.questionnaires[uuid].poem_score !== null || this.questionnaires[uuid].scorad_score !== null)
      .reduce((filtered, uuid) => {
        filtered[uuid] = this.questionnaires[uuid];
        return filtered;
      }, {});
  }
},
  async mounted() {
    try {
      this.questionnaires = await api.getResults();
      // this.questionnaires = [
      //     {
      //         submited_time: '2023-09-20 12:00',
      //         poem_score: 25,
      //         scorad_score: 30,
      //     },
      //     {
      //         submited_time: '2023-09-19 14:00',
      //         poem_score: 30,
      //         scorad_score: 25,
      //     }
      // ];
      console.log("results", this.questionnaires);
    } catch (error) {
      console.error("There was an error fetching the questionnaires:", error);
    }
  },
  methods: {
    // TODO: add results to router
    async goToResults(uuid) {
      this.$router.push('/results/' + uuid);
    },
    async goToNewForm() {
      try {
        const {entityId, poemId, poscoradId} = await api.getNewFormId();
        this.$router.push({ name: 'FillFormRoute', params: { entityId, poemId, poscoradId } });
      } catch (error) {
        console.error("There was an error fetching the new form id:", error);
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
      return new Date(dateString).toLocaleString('en-US', options);
      // const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' };
      // return new Date(dateString).toLocaleString('en-US', options);
    },
  },
}

</script>

<style scoped>

.welcome-container {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  align-self: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  justify-content: space-evenly;
}

.welcome, .landing-info {
  margin-bottom: 1em;
}

.welcome {
  font-size: 2.2em;
}

.landing-info {
  font-size: 1.2em;
  width: 80%;
}

.more-info {
  font-size: 0.8em;
  margin-top: 0.5em;
}


.new-form-button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 500;
  font-size: 1.2em;
  width: 60%;
  max-width: 400px;
  margin: 1em 5px;
  padding: 10px 20px;
  border-radius: 7px;
  border: none;
  background-color: #064896;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.table-container {
  width: 80%;
  margin: 0 auto;
}

<style scoped>
.table-responsive {
  margin-top: 20px;
  width: 100%;
  margin: 20px auto;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-responsive table {
  width: 100%; /* Ensure table takes full width of the container */
}

/* Styling for the table */
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

/* Blue header */
.thead-dark th {
  background-color: #064896;
  color: white;
  border: none;
}

/* Roundish corners */
.table th, .table td {
  border: none;
  padding: 10px;
}

/* Zebra striping for rows, optional */
.table tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

/* Hover effect for rows */
.table tbody tr:hover {
  background-color: #e1f5fe;
}

/* Optional: Animation for hover effect */
.table tbody tr {
  transition: background-color 0.3s ease-in-out;
}

</style>
