<template>
  <QuestionnaireBase
    :questionnaire-title="title"
    :content="content"
    :scroll-start="0"
    :scroll-end="50"
    :show-section-title="showSectionTitle"
    :is-results-page="isResultsPage"
    :score="score"
  />
</template>
  
  <script>
  import QuestionnaireBase from './QuestionnaireBase.vue';
  
  export default {
    components: { QuestionnaireBase },
    props: {
      showSectionTitle: {
        type: Boolean,
        required: false,
        default: true,
      },
      isResultsPage: {
        type: Boolean,
        required: false,
        default: false,
      },
      score: {
        type: String,
        required: false,
        default: 'NA',
      },
    },
    data() {
      return {
        title: '1. POEM', // (Patient Oriented Eczema Measure)
        // showHelpText: false,
        content: [
          {
            section: 'Section 1',
            questions: [
              {
                id: "q1",
                type: "multipleChoice",
                text:
                  '<span style="font-weight: bold;">1. Over the last week,</span> how many days has your skin been <span style="font-weight: bold;">itchy</span> due to eczema. <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
                options: ["No days", "1-2 days", "3-4 days", "5-6 days", "7 days"],
              },
              {
                id: "q2",
                type: "multipleChoice",
                text:
                  '<span style="font-weight: bold;">2. Over the last week,</span> how many nights has your <span style="font-weight: bold;">sleep</span> been disturbed due to eczema. <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
                options: ["No days", "1-2 days", "3-4 days", "5-6 days", "7 days"],
              },
              {
                id: "q3",
                type: "multipleChoice",
                text:
                  '<span style="font-weight: bold;">3. Over the last week,</span> how many days has your skin been <span style="font-weight: bold;">bleeding</span> due to eczema. <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
                options: ["No days", "1-2 days", "3-4 days", "5-6 days", "7 days"]
              },
              {
                id: "q4",
                type: "multipleChoice",
                text:
                  '<span style="font-weight: bold;">4. Over the last week,</span> how many days has your skin been <span style="font-weight: bold;">weeping or oozing</span> clear fluid due to eczema. <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
                options: ["No days", "1-2 days", "3-4 days", "5-6 days", "7 days"]
              },
              {
                id: "q5",
                type: "multipleChoice",
                text:
                  '<span style="font-weight: bold;">5. Over the last week,</span> how many days has your skin been <span style="font-weight: bold;">cracked</span> due to eczema. <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
                options: ["No days", "1-2 days", "3-4 days", "5-6 days", "7 days"]
              },
              {
                id: "q6",
                type: "multipleChoice",
                text:
                  '<span style="font-weight: bold;">6. Over the last week,</span> how many days has your skin been <span style="font-weight: bold;">flaking off</span> due to eczema. <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
                options: ["No days", "1-2 days", "3-4 days", "5-6 days", "7 days"]
              },
              {
                id: "q7",
                type: "multipleChoice",
                text:
                  '<span style="font-weight: bold;">7. Over the last week,</span> how many days has your skin felt <span style="font-weight: bold;">dry or rough</span> due to eczema. <span style="color: grey; font-style: italic;">Please choose one of the boxes below.</span>',
                options: ["No days", "1-2 days", "3-4 days", "5-6 days", "7 days"]
              },
            ]
          }
        ],
      };
    },
  };
  </script>
